import { notifications } from "@mantine/notifications";
import confetti from "canvas-confetti";
import {
  IconCheck,
  IconConfetti,
  IconInfoCircle,
  IconX,
} from "@tabler/icons-react";
import { vars } from "~/config/theme";

import * as classes from "./notifications.css";

const count = 200;
const defaults = {
  origin: { y: 0.6 },
};

function fire(
  particleRatio: number,
  opts: {
    spread?: number;
    startVelocity?: number;
    decay?: number;
    scalar?: number;
  }
) {
  confetti({
    ...defaults,
    ...opts,
    particleCount: Math.floor(count * particleRatio),
    zIndex: 100000,
  });
}

export const showNotication = (
  message: string,
  type?: "error" | "success" | "tada",
  clean?: boolean
) => {
  if (type === "tada") {
    fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });
    fire(0.2, {
      spread: 60,
    });
    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }

  if (clean) {
    notifications.clean();
  }

  notifications.show({
    message,
    color:
      type === "error"
        ? vars.colors.red[7]
        : type === "success"
        ? vars.colors.green[7]
        : vars.colors.dark[6],
    icon:
      type === "error" ? (
        <IconX />
      ) : type === "success" ? (
        <IconCheck />
      ) : type === "tada" ? (
        <IconConfetti />
      ) : (
        <IconInfoCircle />
      ),
    autoClose: 10_000,
    classNames: {
      root: classes.notification,
    },
  });
};
